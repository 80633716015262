<template>
	<div class="cs-find-us-block cs-block" :class="styleClass">
		<div class="cs-base-block">
			<div class="container">
				<CSFindUs :data="data" />
			</div>
		</div>
	</div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
	name: "FindUs",
	components: {
		CSFindUs: () => import("../components/CSFindUs.vue"),
	},
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: computed('FindUs')
}
</script>

<style lang="scss" scoped>
@import "../styles/main.scss";

.cs-find-us-block {
    &.cs-style-dark ::v-deep {
        .cs-fu-tag label {
            color: #fff !important;
        }
    }
    ::v-deep {
        /* top buttons */
        .buttons.cs-advanced-button .button-group {
            margin-bottom: 15px;
			&:last-child {

					margin-bottom: 0;

			}
			@media (max-width: 991px) {
				flex-direction: column;
				margin-bottom: 25px;
                align-items: flex-start;
			}
			@media (max-width: 575px) {
				align-items: flex-start;
			}
			.additional-info {
				padding: 0;
                width: 60%;
				@media (max-width: 991px) {
					width: 100%;
				}
				h5 {
					@include small-title;
				}
				span {
					@include text;
				}
			}
			.button {
				margin-top: 0;
				padding: 0;
                width: 60%;
                max-width: unset;
				@media (max-width: 991px) {
                    justify-content: flex-start;
				}
				@media (max-width: 575px) {
					width: 60%;
				}
				.cs-button {
                    display: block;
					@media (max-width: 991px) {
						width: 100%;
					}
				}
                .cs-button-container {
                    @media (max-width: 991px) {
                        width: 100%;
                    }
                }
			}
		}

        /* btns styles */
        // .button-group {
		// 	.cs-button {
		// 		@include base-transition;
		// 		background: $theme_color !important;
		// 		color: $text_color_over_theme_color !important;
		// 		font-weight: 500;
		// 		border: 1px solid $theme_color;
		// 		min-width: 130px;
		// 		padding: 15px !important;
		// 		border-radius: 0px !important;
		// 		&:hover {
		// 			border-color: $theme_color !important;
		// 			background: transparent !important;
        //             color: $secondary_text_color !important;
		// 		}
		// 	}
		// }
		// .button-group:nth-child(even) .cs-button {
		// 	color: $secondary_text_color !important;
		// 	border: 1px solid $theme_color !important;
		// 	background: transparent !important;

		// 	&:hover {
		// 		background: $theme_color !important;
        //         color: #fff !important;
		// 	}
		// }

        .cs-title {
            @media (max-width: 767px) {
                margin-top: 20px;
            }
        }
    
        .cs-action-head {
			.cs-fu-tags {
				margin: 0px 0 20px;
			}
			.cs-fu-tag {
				@include base-transition;
				padding: 10px 0;
				font-weight: 400;
				width: fit-content;
				border: none !important;
				label {
					@include base-transition;
					margin: 0;
					cursor: pointer;
					&:hover {
						opacity: 0.7;
					}
				}
				input {
					margin: 0 10px 0 0;
					cursor: pointer;
				}
				&:hover {
					background: transparent !important;
				}
			}
		}

        h3.tag-title {
            margin: 40px 0 20px;
        }

        span.contact-info {
			@include tag-btn;
            background-color: #dadada;
			display: flex;
			align-items: center;
			width: fit-content;
			color: #000;
			span {
				display: inline-block;
			}
		}
        .fu-text {
			@include text;
			margin: 0 !important;
			display: block;
		}
        .fu-small.fu-card,
		.text-area {
			div:not(.cs-image-container, .open-contact-buttons) {
				@include text;
				margin-bottom: 0px;
				color: #333;
			}
		}

        .fu-card {
			.button-group a:not(span.cs-button a) {
				@include base-transition;
				background: $theme_color !important;
				color: $secondary_text_color !important;
				font-weight: 500;
				border: 1px solid $theme_color;
				min-width: 130px;
				padding: 15px !important;
				border-radius: 0px !important;
				&:hover {
					border-color: $theme_color !important;
					background: transparent !important;
                    color: $secondary_text_color !important;
				}
			}
			.button-group:nth-child(even) a:not(span.cs-button a) {
				color: $secondary_text_color !important;
                border: 1px solid $theme_color !important;
                background: transparent !important;

                &:hover {
                    background: $theme_color !important;
                    color: #fff !important;
                }
			}
		}
        .fu-large.fu-card {
            .open-contact-buttons {
                margin-top: 20px;
                display: flex;
                justify-content: flex-end;
            }
            .card-back {
                margin-bottom: 30px;
                padding: 20px;
                background: #f5f5f5;
            }
            .card-front {
                overflow: hidden;
                display: flex;
                margin-bottom: 30px;
                border: none;
                border-radius: 0;
                background: #f5f5f5;
                @media (max-width: 991px) {
                    flex-direction: column;
                }
                .fu-image {
                    padding: 25px;
                    -ms-flex: 0 0 33%;
                    flex: 0 0 33%;
                    max-width: 33%;
                    display: flex;
                    align-items: center;
                    @media (max-width: 991px) {
                        flex: 0 0 100%;
                        -ms-flex: 0 0 100%;
                        max-width: 100%;
                    }

                    img {
						width: 100%;
						height: calc(100% - 25px * 2);
						object-fit: cover;
						@media (max-width: 991px) {
							position: relative;
							height: 250px;
						}
					}
                    &:after {
                        @media (max-width: 991px) {
                            padding-bottom: 0;
                        }
                        content: "";
                        display: block;
                        padding-bottom: 100%;
                    }
                }
                .text-area {
                    padding: 25px;
                    padding-left: 50px;
                    -ms-flex: 0 0 67%;
                    flex: 0 0 67%;
                    max-width: 67%;
                    @media (max-width: 991px) {
                        flex: 0 0 100%;
                        -ms-flex: 0 0 100%;
                        max-width: 100%;
                        padding-left: 30px;
                    }
                    .cs-image-container {
                        position: relative;
                        width: 180px;
                    }
                    h4 {
                        @include small-title;
                        margin: 20px 0;
                    }
                }
            }
        }
        
        .fu-small.fu-card {
            background: #f5f5f5;
            height: calc(100% - 15px);
            margin-bottom: 15px;
            overflow: hidden;
            border-radius: 0;
            border: none;
            
            h4 {
                @include small-title;
            }

            .cs-image-container {
                height: 250px;
                img {
                    border-bottom-left-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                }
            }

            .open-contact-buttons {
                margin: 40px 0 15px;
                text-align: center;
                ::v-deep {
                    .button-group {
                        margin: 0;
                    }
                }
            }
        }
        .contact-card {
			border: none;
            border-radius: 0;
			background: transparent;
			-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
			-moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
			padding: 15px;
			h4 {
				@include small-title;
			}
			span {
				@include text;
				margin-bottom: 15px;
				display: inline-block;
                p img {
                    object-fit: cover;
                    max-width: 100%;
                    max-height: 300px;
                }
			}
			.btns {
				text-align: left;
				.button {
					margin-top: 0;
                    justify-content: flex-start;
				}
				.button-group {
					@media (max-width: 500px) {
						width: 60%;
					}
					.cs-button {
                        display: inline-block;
						@media (max-width: 500px) {
							width: 100%;
						}
					}
                    .cs-button-container {
                        @media (max-width: 500px) {
							width: 100%;
						}
                    }
				}
                .button-group {
                    .cs-button {
                        color: $secondary_text_color !important;
                        &:hover {
                            color: $secondary_text_color !important;
                        }
                    }
                }
                .button-group:nth-child(even) .cs-button {
                    color: $secondary_text_color !important;
                    &:hover {
                        color: $secondary_text_color !important;
                    }
                }
			}
		}
		.open-contact-buttons button {
			@include base-transition;
            background: $theme_color !important;
            color: $secondary_text_color !important;
            font-weight: 500;
            border: 1px solid $theme_color !important;
            min-width: 130px;
            padding: 15px !important;
            border-radius: 0px !important;
            &:hover {
                border: 1px solid $theme_color !important;
                background: transparent !important;
                color: $secondary_text_color !important;
            }
		}
    }
}
</style>
